import GIFT from "../../../assets/image/Game/PlantTree/gift-coin.png";
import { UserLeaderBoard } from "../../../models/LeaderBoard";

export default function ItemRating({ data }: { data: UserLeaderBoard }) {
  return (
    <div className="mb-3 rounded-3xl">
      <div className="relative py-3 top-0 grid grid-cols-12 border-select-day px-2 gap-1 rounded-3xl bg-profile-tree">
        <div className="relative top-0 w-12 h-12 col-span-2">
          <img src={data?.image_url} className="w-12 h-12" />
          <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 font-semibold-so">
            {data?.top}
          </div>
        </div>
        <div className="col-span-9">
          <div className="grid grid-cols-12">
            <div className="text-[16px] text-left col-span-9 font-semibold-so text-[#242E49]">
              {data?.consumer_name}
            </div>
            <div className="text-[#4E9ABB] col-span-3 font-semibold-so flex items-center justify-end">
              {data?.point} xô
            </div>
          </div>
          <div className="grid grid-cols-12">
            <div className="text-[#59C2DD] col-span-4 font-light-so text-[14px] flex items-center justify-start">
              {data?.consumer_phone}
            </div>
            <div className="text-[12px] col-span-8 text-[#242E49] flex items-center justify-end">
              {data?.lasted_update}
            </div>
          </div>
        </div>
        <div className="col-span-1">
          <div className="text-[#4E9ABB] font-semibold-so flex items-center h-full">
            <img src={GIFT} />
          </div>
        </div>
      </div>
    </div>
  );
}
