import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LeaderBoardServices } from "../../../services/apiService/LeaderBorad";
import HeaderFrameLeaderBoard from "../../../components/HeaderFrame/HeaderFrameLeaderBoard";
import BACK from "../../../assets/image/Header/back-header.png";
import RewardsLeaderBoard from "../../../components/RewardsLeaderBoard/RewardsLeaderBoard";

export default function LeaderBoardPlantTree() {
  const { id } = useParams();
  const [top, setTop] = useState<number>(100);
  const [listBoard, setListBoard] = useState<any>();
  const [page, setPage] = useState<number>(1);
  const [current, setCurrent] = useState("1");
  const [typeGift, setDataTypeGift] = useState("week");
  const [totalPrize, setTotalPrize] = useState<number>(0);

  const navigation = useNavigate();
  const handleD = (data: number) => {
    setPage(data);
  };

  const getData = (id: string, top: any) => {
    LeaderBoardServices.LeaderBoardPlantTree(id as string, top as number)
      .then((res: any) => {
        console.log(res);
        setListBoard(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };
  useEffect(() => {
    getData(id as string, top);
  }, []);
  const handleBack = () => {
    navigation(`/play-game/${id}`);
  };
  const hanldeClick = (e: any, status_gifts: boolean) => {
    setCurrent(e.target.id);
    if (status_gifts) {
      setDataTypeGift("week");
      getData(id as string, "100");
    } else {
      setDataTypeGift("month");
      getData(id as string, "10");
    }
  };
  return (
    <div>
      <HeaderFrameLeaderBoard
        title="Danh sách trúng thưởng"
        handleBack={handleBack}
        iconBack={BACK}
        footer=""
        changeBackgroundColor="bg-[#DEFAFD]"
      >
        <RewardsLeaderBoard
          isDisableButton={true}
          title1="Top 100"
          title2="Top 10"
          type={"play-game"}
          handleD={handleD}
          page={page}
          totalPrize={totalPrize}
          typeGift={typeGift}
          current={current}
          hanldeClick={hanldeClick}
          listBoard={listBoard}
        />
      </HeaderFrameLeaderBoard>
    </div>
  );
}
