import ChooseHome from "../../../components/NavChoosePageHome/ChooseHome";
import ICON_GAME_COLOR from "../../../assets/image/Home/icon-game-color.png";
import { ListGame } from "../../../models/HomePage";
import BannerPromotion from "./BannerPromotion";
import "react-multi-carousel/lib/styles.css";

export default function Promotion({ lstGame }: { lstGame: any }) {
  console.log(lstGame);
  return (
    <div className="mt-5">
      <div className="px-5">
        <ChooseHome
          value="Xem tất cả"
          icon={ICON_GAME_COLOR}
          name="KHUYẾN MÃI SIÊU HỜI"
          navigate="/list-game/chanel_id"
        />
      </div>
      {lstGame?.games?.length === 1 ? (
        <div className="flex justify-center pb-5 pl-2">
          {lstGame?.games?.map((res: ListGame) => (
            <div key={res.game_id} className="flex justify-center">
              <BannerPromotion
                id={res?.game_id}
                type={res?.game_type}
                img={res?.campaign_banner_img_url}
                campaign_id={res?.campaign_id}
                name={res?.campaign_name}
                campaign_time={res?.campaign_time}
                campaign_type={res?.campaign_type}
              />
            </div>
          ))}
        </div>
      ) : (
        <div className="overhand pb-5 pl-5">
          {lstGame?.games?.map((res: ListGame) => (
            <div key={res.game_id}>
              <BannerPromotion
                id={res?.game_id}
                type={res?.game_type}
                img={res?.campaign_banner_img_url}
                campaign_id={res?.campaign_id}
                name={res?.campaign_name}
                campaign_time={res?.campaign_time}
                campaign_type={res?.campaign_type}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
