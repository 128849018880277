import { useNavigate } from "react-router-dom";
import { ListBrands } from "../../../models/HomePage";

export default function ItemBrand({
  category_id,
  category_image_url,
}: ListBrands) {
  const navigation = useNavigate();
  const handleRedirect = () => {
    navigation(`/list-game/brand/${category_id}`);
  };
  return (
    <div className="w-36" onClick={handleRedirect}>
      <img
        src={category_image_url}
        loading="lazy"
        alt="Image"
        className="rounded-full h-full w-14"
      />
    </div>
  );
}
