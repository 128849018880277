import React, { useRef, useState, useEffect, useImperativeHandle } from "react";
import styled from "styled-components";

let templateObject_1,
  templateObject_2,
  templateObject_3,
  templateObject_4,
  templateObject_5;
function __makeTemplateObject(cooked, raw) {
  if (Object.defineProperty) {
    Object.defineProperty(cooked, "raw", { value: raw });
  } else {
    cooked.raw = raw;
  }
  return cooked;
}

let Wrapper = styled.div(
  templateObject_1 ||
    (templateObject_1 = __makeTemplateObject(
      [
        "\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n",
      ],
      [
        "\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n",
      ]
    ))
);
let Container = styled.div(
  templateObject_2 ||
    (templateObject_2 = __makeTemplateObject(
      ["\n  width: 100%;\n  ", "\n"],
      ["\n  width: 100%;\n  ", "\n"]
    )),
  function (_a) {
    let aspectRatio = _a.aspectRatio;
    return aspectRatio === "cover"
      ? "\n    position: absolute;\n    bottom: 0;\n    top: 0;\n    left: 0;\n    right: 0;"
      : "\n    position: relative;\n    padding-bottom: " +
          100 / aspectRatio +
          "%;";
  }
);
let ErrorMsg = styled.div(
  templateObject_3 ||
    (templateObject_3 = __makeTemplateObject(
      ["\n  padding: 40px;\n"],
      ["\n  padding: 40px;\n"]
    ))
);
let Cam = styled.video(
  templateObject_4 ||
    (templateObject_4 = __makeTemplateObject(
      [
        "\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n  z-index: 0;\n  transform: rotateY(",
        ");\n",
      ],
      [
        "\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n  z-index: 0;\n  transform: rotateY(",
        ");\n",
      ]
    )),
  function (_a) {
    let mirrored = _a.mirrored;
    return mirrored ? "180deg" : "0deg";
  }
);
let Canvas = styled.canvas(
  templateObject_5 ||
    (templateObject_5 = __makeTemplateObject(
      ["\n  display: none;\n"],
      ["\n  display: none;\n"]
    ))
);

let Camera = React.forwardRef(function (_a, ref) {
  let _b = _a.facingMode,
    facingMode = _b === void 0 ? "user" : _b,
    _c = _a.aspectRatio,
    aspectRatio = _c === void 0 ? "cover" : _c,
    _d = _a.numberOfCamerasCallback,
    numberOfCamerasCallback =
      _d === void 0
        ? function () {
            return null;
          }
        : _d,
    _e = _a.videoSourceDeviceId,
    videoSourceDeviceId = _e === void 0 ? undefined : _e,
    _f = _a.errorMessages,
    errorMessages =
      _f === void 0
        ? {
            noCameraAccessible:
              "No camera device accessible. Please connect your camera or try a different browser.",
            permissionDenied:
              "Permission denied. Please refresh and give camera permission.",
            switchCamera:
              "It is not possible to switch camera to different one because there is only one video device accessible.",
            canvas: "Canvas is not supported.",
          }
        : _f,
    _g = _a.videoReadyCallback,
    videoReadyCallback =
      _g === void 0
        ? function () {
            return null;
          }
        : _g;
  let player = useRef(null);
  let canvas = useRef(null);
  let container = useRef(null);
  let _h = useState(0),
    numberOfCameras = _h[0],
    setNumberOfCameras = _h[1];
  let _j = useState(null),
    stream = _j[0],
    setStream = _j[1];
  let _k = useState(facingMode),
    currentFacingMode = _k[0],
    setFacingMode = _k[1];
  let _l = useState(false),
    notSupported = _l[0],
    setNotSupported = _l[1];
  let _m = useState(false),
    permissionDenied = _m[0],
    setPermissionDenied = _m[1];
  useEffect(
    function () {
      numberOfCamerasCallback(numberOfCameras);
    },
    [numberOfCameras]
  );
  useImperativeHandle(ref, function () {
    return {
      takePhoto: function () {
        let _a, _b, _c, _d;
        if (numberOfCameras < 1) {
          throw new Error(errorMessages.noCameraAccessible);
        }
        if (canvas === null || canvas === void 0 ? void 0 : canvas.current) {
          let playerWidth =
            ((_a =
              player === null || player === void 0
                ? void 0
                : player.current) === null || _a === void 0
              ? void 0
              : _a.videoWidth) || 1280;
          let playerHeight =
            ((_b =
              player === null || player === void 0
                ? void 0
                : player.current) === null || _b === void 0
              ? void 0
              : _b.videoHeight) || 720;
          let playerAR = playerWidth / playerHeight;
          let canvasWidth =
            ((_c =
              container === null || container === void 0
                ? void 0
                : container.current) === null || _c === void 0
              ? void 0
              : _c.offsetWidth) || 1280;
          let canvasHeight =
            ((_d =
              container === null || container === void 0
                ? void 0
                : container.current) === null || _d === void 0
              ? void 0
              : _d.offsetHeight) || 1280;
          let canvasAR = canvasWidth / canvasHeight;
          let sX = void 0,
            sY = void 0,
            sW = void 0,
            sH = void 0;
          if (playerAR > canvasAR) {
            sH = playerHeight;
            sW = playerHeight * canvasAR;
            sX = (playerWidth - sW) / 2;
            sY = 0;
          } else {
            sW = playerWidth;
            sH = playerWidth / canvasAR;
            sX = 0;
            sY = (playerHeight - sH) / 2;
          }
          canvas.current.width = sW;
          canvas.current.height = sH;
          let context = canvas.current.getContext("2d");
          if (
            context &&
            (player === null || player === void 0 ? void 0 : player.current)
          ) {
            context.drawImage(player.current, sX, sY, sW, sH, 0, 0, sW, sH);
          }
          let imgData = canvas.current.toDataURL("image/jpeg");
          return imgData;
        } else {
          throw new Error(errorMessages.canvas);
        }
      },
      switchCamera: function () {
        if (numberOfCameras < 1) {
          throw new Error(errorMessages.noCameraAccessible);
        } else if (numberOfCameras < 2) {
          console.error(
            "Error: Unable to switch camera. Only one device is accessible."
          ); // console only
        }
        let newFacingMode =
          currentFacingMode === "user" ? "environment" : "user";
        setFacingMode(newFacingMode);
        return newFacingMode;
      },
      getNumberOfCameras: function () {
        return numberOfCameras;
      },
    };
  });
  useEffect(
    function () {
      initCameraStream(
        stream,
        setStream,
        currentFacingMode,
        videoSourceDeviceId,
        setNumberOfCameras,
        setNotSupported,
        setPermissionDenied
      );
    },
    [currentFacingMode, videoSourceDeviceId]
  );
  useEffect(
    function () {
      if (stream && player && player.current) {
        player.current.srcObject = stream;
      }
      return function () {
        if (stream) {
          stream.getTracks().forEach(function (track) {
            track.stop();
          });
        }
      };
    },
    [stream]
  );
  return React.createElement(
    Container,
    { ref: container, aspectRatio: aspectRatio },
    React.createElement(
      Wrapper,
      null,
      notSupported
        ? React.createElement(ErrorMsg, null, errorMessages.noCameraAccessible)
        : null,
      permissionDenied
        ? React.createElement(ErrorMsg, null, errorMessages.permissionDenied)
        : null,
      React.createElement(Cam, {
        ref: player,
        id: "video",
        muted: true,
        autoPlay: true,
        playsInline: true,
        mirrored: currentFacingMode === "user" ? true : false,
        onLoadedData: function () {
          videoReadyCallback();
        },
      }),
      React.createElement(Canvas, { ref: canvas })
    )
  );
});
Camera.displayName = "Camera";
let initCameraStream = function (
  stream,
  setStream,
  currentFacingMode,
  videoSourceDeviceId,
  setNumberOfCameras,
  setNotSupported,
  setPermissionDenied
) {
  let _a;
  // stop any active streams in the window
  if (stream) {
    stream.getTracks().forEach(function (track) {
      track.stop();
    });
  }
  let constraints = {
    audio: false,
    video: {
      deviceId: videoSourceDeviceId
        ? { exact: videoSourceDeviceId }
        : undefined,
      facingMode: currentFacingMode,
      width: { ideal: 1920 },
      height: { ideal: 1920 },
    },
  };
  if (
    (_a =
      navigator === null || navigator === void 0
        ? void 0
        : navigator.mediaDevices) === null || _a === void 0
      ? void 0
      : _a.getUserMedia
  ) {
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(function (stream) {
        setStream(handleSuccess(stream, setNumberOfCameras));
      })
      .catch(function (err) {
        handleError(err, setNotSupported, setPermissionDenied);
      });
  } else {
    let getWebcam =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia;
    if (getWebcam) {
      getWebcam(
        constraints,
        function (stream) {
          setStream(handleSuccess(stream, setNumberOfCameras));
        },
        function (err) {
          handleError(err, setNotSupported, setPermissionDenied);
        }
      );
    } else {
      setNotSupported(true);
    }
  }
};
let handleSuccess = function (stream, setNumberOfCameras) {
  navigator.mediaDevices.enumerateDevices().then(function (r) {
    return setNumberOfCameras(
      r.filter(function (i) {
        return i.kind === "videoinput";
      }).length
    );
  });
  return stream;
};
let handleError = function (error, setNotSupported, setPermissionDenied) {
  console.error(error);
  //https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
  if (error.name === "PermissionDeniedError") {
    setPermissionDenied(true);
  } else {
    setNotSupported(true);
  }
};

export { Camera };
