import PopupOverlay from "../components/BackgroundPopup/PopupOverLay";

export default function PopupLogin({
  isShow,
  children,
}: {
  isShow: boolean;
  children: React.ReactNode;
}) {
  return (
    <PopupOverlay isShow={isShow}>
      <div className="px-5">
        <div className="bg-login font-light-so">{children}</div>
      </div>
    </PopupOverlay>
  );
}
