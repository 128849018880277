export default function TabReward({
  item,
  index,
  colorEven,
  colorOdd,
  colorText,
}: {
  item: any;
  index: number;
  colorEven: string;
  colorOdd: string;
  colorText: string;
}) {
  return (
    <>
      <tr
        className="min-h-full h-10"
        style={
          index % 2 === 0
            ? { backgroundColor: colorOdd, color: colorText }
            : { backgroundColor: colorEven, color: colorText }
        }
      >
        <td className="font-regular-mon w-20">{item.phone}</td>
        <td className="">
          <div className="w-40 mt-0 ml-3"> {item.gift_name}</div>
        </td>
        <td className="">
          <div className="border-table-listprize text-right pr-1">
            {item.date}
          </div>
        </td>
      </tr>
    </>
  );
}
