import { Fragment } from "react/jsx-runtime";
import "../../assets/css/Popup.css";
import PropTypes from "prop-types";

OverlayPopup.propTypes = {
  children: PropTypes.object,
};
export default function OverlayPopup({
  children,
  isShow,
}: {
  children: any;
  isShow: Boolean;
}) {
  return (
    <Fragment>
      {isShow ? (
        <div
          className="fixed z-50 overflow-y-auto top-0 w-full left-0 show"
          id="static-modal"
          data-modal-backdrop="static"
          tabIndex={-1}
          aria-hidden="true"
        >
          <div className="relative flex items-center justify-center min-height-100vh px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-900 opacity-70" />
              <div className="content-popup-condition w-full">
                <div className=" z-40 px-5">{children}</div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
}
