import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ListReceiptServices } from "../../services/apiService/ListReceiveService";
import { userLocal } from "../../services/localService/localService";
import { setAuthorization } from "../../services/apiService/configURL";
import HEADER_DETAILS from "../../assets/image/Header/header-details.png";
import WARNING from "../../assets/image/ListGift/warning.png";
import URBOX from "../../assets/image/urbox.png";
import BACK from "../../assets/image/Header/back-header.png";

export default function GiftDetails() {
  const navigation = useNavigate();
  const { type_id, id, current } = useParams();
  const [currents, setCurrent] = useState<string>("1");
  let { token } = userLocal.get();
  setAuthorization(token);
  const [data, setData] = useState<any>();
  console.log(type_id, id);
  useEffect(() => {
    const data = {
      type_id: type_id,
      id: id,
    };
    ListReceiptServices.GetDetailsGift(data)
      .then((res: any) => {
        console.log(res);
        setData(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleListGift = () => {
    navigation(`/list-gift/${current}`);
  };
  const handleClick = (e: any, status: string) => {
    setCurrent(e.target.id);
  };
  const { hotline } = JSON.parse(
    localStorage.getItem("HOME_DATA_LOCAL") || "{}"
  );
  return (
    <div>
      <div>
        <div className="w-full h-20 bg-[#5BC2DC] relative">
          <img src={HEADER_DETAILS} />
        </div>
        <div className="w-full flex justify-center relative -top-16 bg-white rounded-t-2xl">
          <div
            className="absolute top-5 left-5 w-12 z-50"
            onClick={handleListGift}
          >
            <img src={BACK} className="w-9" />
          </div>
          <div className="relative rounded-t-2xl w-full">
            <img
              src={data?.gift_rectangle_image_url}
              alt="Image"
              className="w-full rounded-t-2xl"
            />
            <div className="bg-[#F2FCFF] pt-2 rounded-b-2xl text-[20px] font-semibold-so w-full">
              <div className="px-5 pb-1">{data?.gift_name}</div>
              {data?.gift_amount !== "0 Đ" && (
                <div className="flex justify-end mt-1">
                  <div className="bg-[#B5E0EE] pl-[6px] rounded-tl-3xl rounded-br-2xl relative bottom-0 right-0 w-44">
                    <div className=" bg-[#5BC2DC] text-white rounded-br-2xl py-1 text-center rounded-tl-3xl font-medium-so">
                      {data?.gift_amount}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {data?.egift && (
          <div className="relative -top-16 px-3">
            <div>
              {data?.egift ? (
                <div className="tab-exchange-gifts flex justify-between items-center align-middle">
                  <div className="flex flex-col items-center justify-center flex-auto">
                    <button
                      key="1"
                      id={"1"}
                      disabled={currents === "1"}
                      onClick={(e) => handleClick(e, "quotation")}
                      className="font-medium-mon leading-4 mt-[8px] text-[11px]"
                    >
                      Mã nhận quà
                    </button>
                    {currents === "1" ? (
                      <div className="w-24 h-[3px] border-tab mt-[2px]"></div>
                    ) : null}
                  </div>
                  <div className="flex flex-col items-center justify-center flex-auto">
                    <button
                      key="2"
                      id={"2"}
                      disabled={currents === "2"}
                      onClick={(e) => handleClick(e, "quotation")}
                      className="font-medium-mon leading-4 mt-[8px] text-[11px]"
                    >
                      Điều kiện áp dụng
                    </button>
                    {currents === "2" ? (
                      <div className="w-24 h-[3px] border-tab mt-[2px]"></div>
                    ) : null}
                  </div>
                  <div className="flex flex-col items-center justify-center flex-auto">
                    <button
                      key="3"
                      id={"3"}
                      disabled={currents === "3"}
                      onClick={(e) => handleClick(e, "done")}
                      className="font-medium-mon leading-4 mt-[8px] text-[12px]"
                    >
                      Địa điểm áp dụng
                    </button>
                    {currents === "3" ? (
                      <div className="w-24 h-[3px] border-tab mt-[2px]"></div>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>
            {currents === "1" ? (
              <div className="flex flex-col items-center">
                <div className="font-medium-so">
                  <div className="flex justify-center mt-3">
                    <img
                      src={data?.egift_code_image}
                      className={`${
                        data?.egift_code_display === "QR code" ? "w-6/12" : ""
                      }`}
                    />
                  </div>
                  <div className="px-10 text-center font-light-so my-2 flex mt-5">
                    <span>
                      <img src={WARNING} className="w-10" />
                    </span>
                    Không cung cấp ảnh chụp màn hình cho nhân viên khi thanh
                    toán!
                  </div>
                  <div className="flex justify-center">
                    <img src={URBOX} />
                  </div>
                  {data?.gift_code && (
                    <div className="text-center">
                      Egift Code:{" "}
                      <span className="text-[#ED3136]">{data?.gift_code}</span>
                    </div>
                  )}
                  {data?.egift_link && (
                    <div className="text-center">
                      Link:{" "}
                      <span className="text-[#ED3136]">
                        <a href={data?.egift_link} target="_blank">
                          {data?.egift_link}
                        </a>
                      </span>
                    </div>
                  )}
                  {data?.egift_pin && (
                    <div className="text-center">
                      Pin:{" "}
                      <span className="text-[#ED3136]">{data?.egift_pin}</span>
                    </div>
                  )}
                  {data?.egift_serial && (
                    <div className="text-center">
                      Serial:{" "}
                      <span className="text-[#ED3136]">
                        {data?.egift_serial}
                      </span>
                    </div>
                  )}
                  {data?.expired_date && (
                    <div className="text-center">
                      Hạn sử dụng:{" "}
                      <span className="text-[#ED3136]">
                        {data?.expired_date}
                      </span>
                    </div>
                  )}
                  <div className="text-center">
                    Hotline:&nbsp;
                    <span className="text-[#5BC2DC]">
                      <a href={`tel:${data?.hotline}`}>{data?.hotline}</a>
                    </span>
                  </div>
                </div>
              </div>
            ) : null}
            {currents === "2" ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: data?.conditions,
                }}
                className="relative font-light-so h-full point-ul"
                id=""
              ></div>
            ) : currents === "3" ? (
              <div>
                {data?.exchange_locations?.map((res: any) => (
                  <div className="px-5 shadow-item py-2 rounded-2xl mb-2">
                    <div className="font-regular-so">{res.location_name}</div>
                    <div className="text-[12px] font-light-so text-[#636363] text-reponsive">
                      {res.location_address}
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        )}
        {!data?.egift && (
          <div className="text-[20px] font-semibold-so px-5 mt-5 relative -top-16">
            <div
              dangerouslySetInnerHTML={{
                __html: data?.conditions as string,
              }}
              className="font-light-so text-[16px] mt-5"
            ></div>
          </div>
        )}
        <div className="h-40"></div>
        <div className="flex flex-col items-center justify-center fixed bottom-0 w-full">
          <button
            className="relative z-10 bg-[#5BC2DC] text-white py-4 rounded-xl w-11/12 shadow-item"
            style={{
              border: `2px solid #D7F7FF`,
            }}
            onClick={handleListGift}
          >
            Đồng ý
          </button>
          <div className="h-8 bg-white w-full"></div>
        </div>
      </div>
    </div>
  );
}
