import { formatCoin } from "../../utils/formatData";
import ICON_COIN from "../../assets/image/Home/icon-coin.png";
import RangeSlider from "../RangeSlider/RangeSlider";
import { useSelector } from "react-redux";
import { homeBanner } from "../../redux/slices/HomeSlice";
import SEE_DETAIL from "../../assets/image/AccumulatePoint/see-detail.png";

const ThumbComp = () => {
  return <div></div>;
};

const ProgressComp = () => {
  return (
    <div
      style={{
        width: 400,
        height: 6,
        borderRadius: 10,
        background: "#5BC2DC",
      }}
    ></div>
  );
};
export default function LoyaltyCards({
  imageBackground,
  coin,
  totalCoin,
  handleChange,
}: {
  imageBackground: string;
  coin: number;
  totalCoin: number;
  handleChange: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  const { homeData } = useSelector(homeBanner);
  console.log(homeData);
  return (
    <div>
      <div>
        <img
          alt="Image"
          src={imageBackground}
          loading="lazy"
          className="w-full relative z-0"
        />
        <div className="absolute top-0 p-4">
          <div className="text-[#333333] font-light-so text-[16px]">
            Tài Phạm đẹp trai
          </div>
          <div className="flex items-center">
            <div className="mr-2">
              <img alt="Image" src={ICON_COIN} loading="lazy" />
            </div>
            <div className="font-semibold-so">{formatCoin(coin)} Pepcoin</div>
          </div>
        </div>
        <div className="absolute z-50 bottom-0 p-4 w-full">
          <div className="relative">
            <div className="flex justify-between relative -bottom-2">
              <div className="text-[20px]">Hạng Đồng</div>
              <div className="font-regular-so">
                {coin}/{totalCoin}
              </div>
            </div>
            <RangeSlider
              style={{ width: "100%", height: 30 }}
              steps={1}
              min={0}
              max={totalCoin}
              value={coin}
              Thumb={ThumbComp}
              Progress={ProgressComp}
              onChange={(e: any) => handleChange(e)}
              showProgress
            />
            <div>
              <button className="bg-[#4E9ABB] flex justify-around items-center text-[14px] text-white px-4 py-1 rounded-2xl font-light-so">
                Xem chi tiết
                <span className="ml-1">
                  <img alt="Image" src={SEE_DETAIL} />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
