import { Fragment } from "react/jsx-runtime";
import ActiveCatcher from "./ActiveCatcher";
import OpenActiveCatcher from "./OpenActiveCatcher";

export default function PickupItem({
  current_number,
  animation,
  gap_active,
  open_gap,
  pre_number,
  number_gap,
  random_location,
  game,
}: {
  current_number: number;
  animation: boolean;
  gap_active: boolean;
  open_gap: boolean;
  pre_number: string;
  number_gap: number;
  random_location: number[];
  game: any;
}) {
  console.log(game?.claw_image_url);
  return (
    <Fragment>
      {game && (
        <Fragment>
          <div className="absolute top-0 w-full px-10">
            <div className="grid grid-cols-5">
              <div className="col-span-1 flex justify-center">
                {current_number === 1 ? (
                  <>
                    {animation ? (
                      <ActiveCatcher
                        active_pickup={game?.claw_image_url}
                        img={game?.game_items[0]}
                        gap_active={gap_active}
                        open_gap={open_gap}
                      />
                    ) : (
                      <OpenActiveCatcher pre_number={pre_number} />
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="col-span-1 flex justify-center">
                {current_number === 2 ? (
                  <>
                    {animation ? (
                      <ActiveCatcher
                        img={game?.game_items[1]}
                        gap_active={gap_active}
                        open_gap={open_gap}
                      />
                    ) : (
                      <OpenActiveCatcher pre_number={pre_number} />
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="col-span-1 flex justify-center">
                {current_number === 3 ? (
                  <>
                    {animation ? (
                      <ActiveCatcher
                        img={game?.game_items[2]}
                        gap_active={gap_active}
                        open_gap={open_gap}
                      />
                    ) : (
                      <OpenActiveCatcher pre_number={pre_number} />
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="col-span-1 flex justify-center">
                {current_number === 4 ? (
                  <>
                    {animation ? (
                      <ActiveCatcher
                        img={game?.game_items[3]}
                        gap_active={gap_active}
                        open_gap={open_gap}
                      />
                    ) : (
                      <OpenActiveCatcher pre_number={pre_number} />
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="col-span-1 flex justify-center">
                {current_number === 5 ? (
                  <>
                    {animation ? (
                      <ActiveCatcher
                        img={game?.game_items[1]}
                        gap_active={gap_active}
                        open_gap={open_gap}
                      />
                    ) : (
                      <OpenActiveCatcher pre_number={pre_number} />
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="absolute bottom-16 w-full px-10">
            <div className="grid grid-cols-5">
              <div className="col-span-1 flex justify-center">
                <div>
                  {number_gap === 1 ? (
                    ""
                  ) : (
                    <img
                      src={game?.game_items[0]}
                      className="relative w-12 bottom-3 rotate-left"
                      loading="lazy"
                    />
                  )}
                </div>
              </div>
              <div className="col-span-1 flex justify-center">
                <div>
                  {number_gap === 2 ? (
                    ""
                  ) : (
                    <img
                      src={game?.game_items[1]}
                      loading="lazy"
                      className="relative w-12 bottom-2 rotate-right"
                    />
                  )}
                </div>
              </div>
              <div className="col-span-1 flex justify-center">
                <div>
                  {number_gap === 3 ? (
                    ""
                  ) : (
                    <img
                      src={game?.game_items[2]}
                      loading="lazy"
                      className="relative w-12 bottom-3 rotate-left"
                    />
                  )}
                </div>
              </div>
              <div className="col-span-1 flex justify-center">
                <div>
                  {number_gap === 4 ? (
                    ""
                  ) : (
                    <img
                      src={game?.game_items[3]}
                      loading="lazy"
                      className="relative w-12 bottom-2 rotate-right"
                    />
                  )}
                </div>
              </div>
              <div className="col-span-1 flex justify-center">
                <div>
                  {number_gap === 5 ? (
                    ""
                  ) : (
                    <img
                      src={game?.game_items[1]}
                      loading="lazy"
                      className="relative 
                          w-12
                          bottom-3 rotate-left "
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}
