import OverlayPopup from "../../../components/OverlayPopup/OverlayPopup";
import BG_GIFT from "../../../assets/image/Game/LuckyMachine/bg.gif";
import { useNavigate, useParams } from "react-router-dom";

export default function ReceiveGift() {
  const luotquay = JSON.parse(localStorage.getItem("LUOT_QUAY") || "{}");
  console.log(luotquay);

  const gift = JSON.parse(localStorage.getItem("GIFT_WHEEL") || "{}");
  const { campaign_id, sale_id } = useParams<string>();

  const game_data = JSON.parse(localStorage.getItem("GAME_DATA_LOCAL") || "{}");
  const navigation = useNavigate();
  const handleBack = () => {
    localStorage.removeItem("GIFT_WHEEL");
    localStorage.removeItem("LUOT_QUAY");
    if (parseInt(luotquay) === 0) {
      navigation(`/list-gift`);
    } else {
      navigation(`/halloween_ghost/${campaign_id}/${sale_id}`);
    }
  };
  return (
    <OverlayPopup isShow>
      <div
        style={{
          background: `url(${game_data?.background_image_url})`,
          width: "100vw",
          minHeight: "100vh",
          backgroundPosition: "center",
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          position: "relative",
          left: "-20px",
        }}
        className="px-5"
      >
        <div className="absolute top-1/2 -translate-y-1/2 w-full">
          <img
            src={game_data?.claw_image_url}
            className="animation-tornado-result w-full"
          />
        </div>
        <div className="relative min-h-screen w-full left-0 z-10 animation-popupghost">
          <div className="relative w-full">
            <img
              src={game_data?.title_image_url}
              className="relative w-full left-0"
            />
          </div>
          <div
            style={{
              background: "url(" + game_data?.gift_frame_url + ")",
              backgroundSize: "100% 100%",
              backgroundPosition: "contain",
              width: "100%",
              minHeight: "500px",
            }}
          >
            <div className="relative top-8 mb-10">
              <img
                src={
                  gift?.no_gift
                    ? game_data?.title_no_gift_image_url
                    : game_data?.title_result_image_url
                }
              />
              <div>{!gift?.no_gift && gift?.gift_name}</div>
              <div className="flex justify-center w-full mt-5">
                <div
                  style={{
                    background: "url(" + BG_GIFT + ")",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "70% 100%",
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img src={gift?.gift_square_image_url} className="w-4/12" />
                </div>
              </div>
              <div
                className="font-light-so px-5 text-[13px] leading-5"
                dangerouslySetInnerHTML={{
                  __html: gift?.instructions_redeeming_rewards,
                }}
              ></div>

              <div className="mt-2">
                <button className="relative" onClick={handleBack}>
                  <img src={game_data?.button_top_image_url} />
                  <div className="absolute flex justify-center top-1/2 -translate-y-1/2 w-full left-1/2 -translate-x-1/2">
                    {parseInt(luotquay) === 0 ? (
                      <div>
                        <img src={game_data?.label_gift_image_url} />
                      </div>
                    ) : (
                      <div className="relative top-0">
                        <img
                          src={game_data?.label_count_image_url}
                          className="w-44"
                        />
                        <div className="absolute text-white top-[40%] -translate-y-1/2 left-1/2 -translate-x-1/2 text-[26px]">
                          <span className="font-semibold-so">
                            {" "}
                            {parseInt(luotquay)}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute z-0 bottom-0 left-0">
          <img src={game_data?.machine_footer_image_url} />
        </div>
      </div>
    </OverlayPopup>
  );
}
