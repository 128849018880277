import OverlayPopup from "../../OverlayPopup/OverlayPopup";
import CLOSEBOARD from "../../../assets/image/Popup/closeBoard.png";

export default function PopupPlantTree({
  isShow,
  handleOpenBoard,
  children,
  imgTitle,
  frameBow,
  title,
}: {
  isShow: Boolean;
  handleOpenBoard: () => void;
  children?: React.ReactNode;
  imgTitle: string;
  frameBow?: string;
  title?: string;
}) {
  return (
    <OverlayPopup isShow={isShow}>
      {isShow && (
        <div className="relative top-[8vh] ">
          <div className="bg-light-animation"></div>
          <div className="absolute -top-[100px] z-30 w-full left-0 flex justify-center">
            <img src={imgTitle} className="w-16" />
          </div>
          <div className="absolute -top-[105px] z-20 w-full">
            <img
              src={frameBow}
              className="relative w-10/12 left-1/2 z-30 -translate-x-1/2"
            />
            <div className="absolute text-white font-bold-so text-[16px] w-full z-30 top-[60%] left-1/2 -translate-x-1/2 -translate-y-1/2">
              {title}
            </div>
          </div>
          <div className="bg-main rounded-t-[55px] rounded-b-[50px] p-2 z-10 overflow-hidden">
            <div className="bg-contain relative rounded-t-[50px] rounded-b-[45px] z-10 overflow-hidden">
              {children}
            </div>
          </div>

          <button
            className="absolute bottom-0 translate-y-1/2 z-40 left-1/2 -translate-x-1/2"
            onClick={handleOpenBoard}
          >
            <img src={CLOSEBOARD} />
          </button>
        </div>
      )}
    </OverlayPopup>
  );
}
