import { Fragment } from "react/jsx-runtime";
import { useNavigate, useParams } from "react-router-dom";
import { userLocal } from "../../../services/localService/localService";
import { useEffect, useState } from "react";
import { GameServices } from "../../../services/apiService/GameService";
import { Campaign } from "../../../models/GameModel";
import HeaderBack from "../../../components/HeaderBack/HeaderBack";
import BACK_HEADER from "../../../assets/image/Header/back-header.png";
import HEADER_DETAILS from "../../../assets/image/Header/header-details.png";
import PopupLogin from "../../../popup/PopupLogin";
import CLOSE from "../../../assets/image/Home/cancel-popup.png";

export default function PrizeGame() {
  let campaign_id = window.location.pathname.split("/")[2];
  const { id } = useParams();
  const [prizeGame, setPrizeGame] = useState<Campaign>();
  const navigation = useNavigate();
  let { token } = userLocal.get();
  const [isShow, setIsShow] = useState(false);
  const handleJoinGame = () => {
    if (!prizeGame?.disable) {
      navigation(`/guide-page-picture/${id}`);
    } else {
      setIsShow(true);
    }
  };
  const getDAta = (campaign_id: string, token: any) => {
    GameServices.GameDetailsApi(campaign_id, token)
      .then((res: any) => {
        console.log(res);
        const checkSting = res?.game_name?.toLowerCase()?.includes("sting");
        const checkAqua = res?.game_name?.toLowerCase()?.includes("aqua");
        console.log(checkSting);
        if (checkSting) {
          navigation(`/special/tc/${id}`);
        } else if (checkAqua) {
          navigation(`/aqua/tc/${id}`);
        }
        localStorage.setItem("DATA_LEADER_BOARD", JSON.stringify(res));
        setPrizeGame(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getDAta(campaign_id, token);
  }, []);
  const handleClickGift = (e: string) => {
    if (!prizeGame?.disable) {
      navigation(e);
    }
  };
  const handleClose = () => {
    setIsShow(false);
  };
  return (
    <Fragment>
      <div className="relative top-0">
        <div className="relative top-0 bg-[#EEF4F6]  rounded-b-3xl">
          <div className="w-full h-20 bg-[#5BC2DC] relative">
            <img src={HEADER_DETAILS} />
          </div>
          <div className="relative -top-14">
            <img
              src={prizeGame?.banner_image_url}
              loading="lazy"
              className="rounded-3xl"
            />
          </div>
          <div className="absolute top-5 px-5 w-full">
            <HeaderBack name="" navigate="/" imageBack={BACK_HEADER} />
          </div>
          <div className="relative">
            <div className="uppercase text-[20px] -mt-12 line-clamp-2 px-3 font-semibold-so pb-1">
              {prizeGame?.campaign_name}
            </div>
          </div>
          <div className="flex justify-end mt-1">
            <div className="bg-[#B5E0EE] pl-[6px] rounded-tl-3xl rounded-br-2xl relative bottom-0 right-0 w-44">
              <div className=" bg-[#5BC2DC] font-light-so text-white rounded-br-2xl py-1 text-center rounded-tl-3xl">
                {prizeGame?.campaign_time}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-4">
        <div
          dangerouslySetInnerHTML={{
            __html: prizeGame?.content_details,
          }}
          className="font-light-so px-6 mb-44 point-ul line-table text-[14px]"
        ></div>
        <div className="flex justify-center flex-col pb-10 fixed bottom-0 w-full px-6 fade-background">
          <div className="grid grid-cols-2 gap-2">
            <div className="col-span-1">
              <button
                className="w-full rounded-xl leading-5 py-2 shadow-item"
                style={{
                  backgroundColor: prizeGame?.gift_button_color,
                  border: `2px solid ${prizeGame?.gift_button_border_color}`,
                  color: prizeGame?.gift_button_text_color,
                }}
                onClick={() =>
                  handleClickGift(`${
                    prizeGame?.campaign_type === "leader_board"
                      ? `/list-leader-board/${id}`
                      : `/list-receive-customer/gift/${id}`
                  }
                    `)
                }
              >
                {prizeGame?.campaign_type === "leader_board" ? (
                  <div className="py-[10px]">Bảng xếp hạng</div>
                ) : (
                  <>
                    Danh sách
                    <br /> Quà tặng
                  </>
                )}
              </button>
            </div>
            <div className="col-span-1">
              <button
                className="w-full rounded-xl leading-5 py-2 shadow-item"
                style={{
                  backgroundColor: prizeGame?.win_button_color,
                  border: `2px solid ${prizeGame?.win_button_border_color}`,
                  color: prizeGame?.win_button_text_color,
                }}
                onClick={() =>
                  handleClickGift(
                    `${
                      prizeGame?.campaign_type === "leader_board"
                        ? `/list-prize-seasons/${id}`
                        : `/list-receive-customer/prize/${id}`
                    }`
                  )
                }
              >
                Danh sách
                <br /> trúng thưởng
              </button>
            </div>
          </div>
          <div className="mt-3">
            <button
              className=" py-3 rounded-xl w-full shadow-item"
              style={{
                backgroundColor: prizeGame?.disable
                  ? "#bdbdbd"
                  : prizeGame?.join_button_color,
                // backgroundColor: "#bdbdbd",
                border: `2px solid ${prizeGame?.join_button_border_color}`,
                color: prizeGame?.join_button_text_color,
              }}
              onClick={handleJoinGame}
            >
              Tham gia ngay
            </button>
          </div>
        </div>
      </div>
      <PopupLogin isShow={isShow}>
        <div className="py-5">
          <div className="uppercase text-[#2D4E68] text-center text-[28px] font-bold-so">
            Thông báo
          </div>
          <div className="py-5">
            Chờ đón nhiều Chương trình Khuyến Mãi siêu hot đang sắp diễn ra!
          </div>
          <div className="absolute -bottom-20 left-1/2 -translate-x-1/2">
            <img src={CLOSE} onClick={handleClose} />
          </div>
        </div>
      </PopupLogin>
    </Fragment>
  );
}
