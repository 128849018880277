import { Pagination } from "antd";
import { LeaderBoard } from "../../models/LeaderBoard";
import WeekLeaderBoard from "./WeekLeaderBoard";

export default function RewardsLeaderBoard({
  handleD,
  page,
  totalPrize,
  typeGift,
  current,
  hanldeClick,
  listBoard,
  title1,
  title2,
  isDisableButton,
  type,
}: {
  handleD: any;
  page: number;
  totalPrize: number;
  typeGift: string;
  current: string;
  hanldeClick: any;
  listBoard: LeaderBoard;
  title1: string;
  title2: string;
  isDisableButton: Boolean;
  type?: string;
}) {
  return (
    <div>
      <div className="mt-2">
        <div className="">
          <div className="tab-leaderboard flex justify-between items-center align-middle mt-5">
            <div className="flex justify-center flex-auto">
              <button
                key="1"
                id={"1"}
                disabled={current === `${1}`}
                onClick={(e) => hanldeClick(e, true)}
                className="font-semibold-mon mt-[6px] leading-4"
              >
                {title1}
              </button>
            </div>
            <div className="flex justify-center flex-auto">
              <button
                key="2"
                id={"2"}
                disabled={current === `${2}`}
                onClick={(e) => hanldeClick(e, false)}
                className="font-semibold-mon mt-[6px] leading-4"
              >
                {title2}
              </button>
            </div>
          </div>
          <div className="box-border mt-3">
            <WeekLeaderBoard
              title={current === "1" ? title1 : title2}
              isDisableButton={isDisableButton}
              listBoard={listBoard}
              typeGift={typeGift}
              type={type}
            />
          </div>
          {current === "2" ? (
            <div className="flex justify-center mt-2">
              {totalPrize > 10 && (
                <Pagination
                  defaultCurrent={page}
                  total={totalPrize}
                  onChange={handleD}
                />
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
