export default function StartLoading({
  width,
  title,
  gift,
}: {
  width: number;
  title: string;
  gift: string;
}) {
  return (
    <div className="absolute left-1/2 -translate-x-1/2 loading-container">
      <div className="loading-bar border-loading-game relative">
        <div className="bubbles" style={{ width: width + "%" }}>
          <div className="w-1 h-1 rounded-full bg-white absolute right-3 opacity-30 top-1"></div>
          <div className="w-2 h-2 rounded-full bg-white absolute right-14 opacity-30 top-[8px]"></div>
          <div className="w-2 h-2 rounded-full bg-white absolute right-10 opacity-30 top-0"></div>{" "}
          <div className="w-1 h-1 rounded-full bg-white absolute right-20 opacity-30 top-1"></div>
          <div className="w-2 h-2 rounded-full bg-white absolute right-28 opacity-30 top-[4px]"></div>
          <div className="w-2 h-2 rounded-full bg-white absolute right-36 opacity-30 top-2"></div>
        </div>
      </div>
      <p className="loading-text relative -top-2">{title}</p>
      {gift && (
        <div className="absolute -right-[32px] -top-[20%] -translate-y-0 w-10 h-10 bg-[#365e7c] rounded-full border-loading-game">
          <img src={gift} className="w-full absolute bottom-1" />
        </div>
      )}
    </div>
  );
}
